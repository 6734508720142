<template>
  <div>
    <page-title :title="dataDetail.subjects" :breadcrumbs-items="breadcrumbsItems"></page-title>

    <v-row class="mb-2">
      <v-col>
        <v-btn color="white" class="mr-4" @click="goBack()" elevation="1">Back</v-btn>
        <v-btn :color="(!showReplyForm) ? 'primary':'error' " class="mr-4" @click="showReplyForm=!showReplyForm" v-if="dataDetail.status != 'CLOSED'">
          <span v-if="!showReplyForm">Reply</span>
          <span v-if="showReplyForm">Cancel</span>
        </v-btn>
        <v-btn color="success" v-if="dataDetail.status != 'CLOSED'" @click.stop="dialog1=true">Close this Ticket</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9" lg="9">
        <transition name="bounce">
          <Reply v-show="showReplyForm" ref="refReply" :parentData="dataDetail" @getDetail="getDetail"></Reply>
        </transition>
        <v-divider class="my-2" v-if="showReplyForm"></v-divider>

        <v-expansion-panels v-model="panel" multiple>
          <!-- Conversation -->
          <v-expansion-panel v-for="(c, ii) in dataDetail.conversation" :key="ii">
            <v-expansion-panel-header ripple>
              <span>{{ c.replied_date }} | {{ c.replied_name }}</span>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <v-card-text>
                <v-sheet min-height="200" v-html="c.body" style="overflow-x:auto"></v-sheet>
              </v-card-text>
              <v-card-text>
                <h4>Attachments:</h4>
                <p v-if="c.attachments.length == 0">No attachments!</p>
                <v-list>
                  <v-list-item v-for="(a, i) in c.attachments" :key="i" class="my-0" dense @click="goToExternalUrl(a.file_src)">
                    <v-list-item-icon>
                      <v-icon>mdi-attachment</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ (a.file_name == '') ? a.file_src : a.file_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Original Request -->
          <v-expansion-panel>
            <v-expansion-panel-header ripple>
              <span>{{ dataDetail.created_date }} | {{ dataDetail.customer_name }}</span>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <v-card-text>
                <v-sheet min-height="200" v-html="dataDetail.body" style="overflow-x:auto"></v-sheet>
              </v-card-text>
              <v-card-text>
                <h4>Attachments:</h4>
                <p v-if="((typeof dataDetail.attachments == 'undefined'))">No attachments!</p>
                <v-list v-if="((typeof dataDetail.attachments !== 'undefined'))">
                  <v-list-item v-for="(item, i) in dataDetail.attachments" :key="i" class="my-0" dense @click="goToExternalUrl(item.file_src)">
                    <v-list-item-icon>
                      <v-icon>mdi-attachment</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ (item.file_name == '') ? item.file_src : item.file_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-card>
          <v-toolbar elevation="0" dense>
            <v-toolbar-title>Ticket Information</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Ticket No.</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.ticket_no }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Priority</v-list-item-title>
                <v-list-item-subtitle>
                  <PriorityChip :value="dataDetail.priority"></PriorityChip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Priority</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.priority }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Posted By</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.customer_name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.customer_email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Submitted</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.created_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Last Update</v-list-item-title>
                <v-list-item-subtitle>{{ dataDetail.last_update }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle>
                  <StatusChip :value="dataDetail.status"></StatusChip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn block color="success" v-if="dataDetail.status != 'CLOSED'" @click.stop="dialog1=true">Close this Ticket</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog1" max-width="400">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>
            <h3>Confirmation</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="dialog1=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <p>Are you sure you want to close this ticket?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click.stop="dialog1=false">Cancel</v-btn>
          <v-btn color="primary" outlined @click="closeTicket()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import PriorityChip from './PriorityChip.vue'
import StatusChip from './StatusChip.vue'
import Reply from './Reply.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueEditor,
    PriorityChip,
    StatusChip,
    Reply,
  },
  beforeMount() {
    this.requiredLogin()
  },
  mounted() {
    this.setTitle('Create Ticket')

    this.ticket_no = this.$route.query.ticket_no

    this.getDetail()
  },

  data: () => ({
    priority: 'LOW',
    priorityItems: ['LOW', 'NORMAL', 'MEDIUM', 'HIGH'],
    source: '-',
    sourceItems: ['-', 'ORDER', 'DELIVERY', 'INVOICE', 'PAYMENT'],
    no_: '-',

    attachmentItems: [],

    ticket_no: null,

    dataDetail: {},

    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
      {
        text: 'Complaint',
        disabled: false,
        href: '/complaint',
      },
      {
        text: 'Detail',
        disabled: true,
        href: '#',
      },
    ],

    showReplyForm: false,

    dialog1: null,
    panel: [0],
  }),

  methods: {
    getDetail() {
      this.showReplyForm = false
      this.showLoadingOverlay(true)
      var params = {
        params: { ticket_no: this.ticket_no },
      }
      this.$axios
        .get('ticket/detail', params)
        .then(response => {
          this.showLoadingOverlay(false)
          this.dataDetail = response.data.data
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.showLoadingOverlay(false)
          // console.log(error)
        })
        .then(() => {
          this.showLoadingOverlay(false)
        })
    },

    async closeTicket() {
      this.showLoadingOverlay(true)

      var formData = new FormData()
      formData.append('ticket_no', this.dataDetail.ticket_no)

      await this.$axios
        .post('ticket/close', formData)
        .then(res => {
          if (res) {
            res = res.data
            this.campaigns = res.data
          }

          this.showLoadingOverlay(false)

          this.$alert(res.message).then(() => {
            this.goBack()
          })
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data)
            // console.log(error.response.status)
            // console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
          }
          // console.log(error.config)
          this.$awn.alert('Internal Server Error')

          this.showLoadingOverlay(false)
        })
    },
  },
}
</script>


<style>
.ql-editor {
  min-height: 300px !important;
}

.bounce-enter-active {
  animation: bounce-in 0.2s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>